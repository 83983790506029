<!-- 简历管理 -->
<template>
  <div class="wrap">
    <el-select
      v-model="valueType"
      placeholder="线上"
      @change="Handle"
      style="float: left"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>

    <div class="get-btn cont">
      <el-dropdown
        v-if="typeof q_status === 'number'"
        split-button
        @command="myselect"
        type="text"
        :style="{ backgroundColor: q_colorStatus[q_status].color }"
      >
        {{ q_colorStatus[q_status].type }}
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            class="dropdown-item"
            :command="index"
            v-for="(item, index) in q_colorStatus.slice(0)"
            :key="index"
            >{{ item.type }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>

      <el-input v-model="keyword" placeholder="姓名" class="inline_input">
        <span slot="suffix" @click="search">
          <i class="el-icon-search search-icon"></i>
        </span>
      </el-input>
      &nbsp;&nbsp;
      <br /><br />
    </div>

    <el-table
      :data="tableData"
      stripe
      style="margin-top: 30px; width: 100%; min-height: 70vh"
      @row-click="goDetail"
      v-loading="loading"
    >
      <el-table-column
        prop="name"
        label="姓名"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="gender"
        label="性别"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="age"
        label="年龄"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系方式"
        width="120"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="resume_education_experience_list[0].education_background"
        label="学历"
        width="100"
        align="center"
      ></el-table-column>

      <el-table-column label="期望薪资" align="center" width="150">
        <template
          v-if="
            scope.row.resume_expect_position_list &&
            scope.row.resume_expect_position_list.length > 0
          "
          slot-scope="scope"
          >{{ scope.row.resume_expect_position_list[0].salary_min }}-{{
            scope.row.resume_expect_position_list[0].salary_max
          }}元</template
        >
      </el-table-column>
      <el-table-column
        prop="expect_position_name_str"
        label="期望岗位"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="期望地区" show-overflow-tooltip align="center">
        <template slot-scope="scope">{{
          scope.row.expect_place_name
        }}</template>
      </el-table-column>
      <!-- <el-table-column label="简历类别" align="center">
        <template slot-scope="scope">{{scope.row.expect_place_name}}</template>
      </el-table-column>-->
      <el-table-column label="简历状态" align="center">
        <template slot-scope="scope">
          <img
            style="width: 80%"
            :src="colorStatus[scope.row.status].iconfont"
          />
        </template>
      </el-table-column>

      <el-table-column label="聊一聊" align="center">
        <template slot-scope="scope">
          <img
            class="chat"
            @click.stop="onChatDetail(scope.row)"
            src="./../../assets/images/icon_img/chat.png"
          />
        </template>
      </el-table-column>

      <el-table-column label="复制微信号" align="center">
        <template slot-scope="scope">
          <img
            
            class="chat"
            @click.stop="onFuzhi(scope.row)"
            src="./../../assets/images/icon_img/weixin.png"
          />
        </template>
      </el-table-column>


      <el-table-column
        prop="create_time"
        label="创建时间"
        align="center"
      ></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getResumeManage, upload_file } from "@/api/tool/index";
// import { require as axios } from "../../utils/http";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      keyword: "", //按姓名搜索
      dialogVisible: false,
      gridData: [],
      dialogTableVisible: false,
      token: "",
      fileList: [],
      loading: true,
      tableData: [],
      //总页码
      pageTotal: null,
      pageNow: 1,
      //简历状态引入方式只支持单色
      //不合格，可复试,发offer,未入职，
      colorStatus: [
        {
          color: "#ccc",
          iconfont: require("./../../assets/images/icon_img/iconweibiaoji.png"),
        },
        {
          color: "#FF435A",
          iconfont: require("./../../assets/images/icon_img/iconbuhege.png"),
        },
        {
          color: "#ff9900",
          iconfont: require("./../../assets/images/icon_img/iconkefushi.png"),
        },
        {
          color: "#3EFF59",
          iconfont: require("./../../assets/images/icon_img/iconfaoffer.png"),
        },
        {
          color: "#1D7BD8",
          iconfont: require("./../../assets/images/icon_img/iconweiruzhi.png"),
        },

        {
          color: "#149c18",
          iconfont: require("./../../assets/images/icon_img/iconruzhi.png"),
        },
      ],

      options: [
        { label: '不限', value: 2 },
        { label: "线上", value: 0 },
        { label: "线下", value: 1 },
      ],
      valueType: 2,

      //查询条件
      q_status: 0,
      q_colorStatus: [
        { color: "#ccc", type: "未标记" },
        { color: "#FF435A", type: "不合格" },
        { color: "#ff9900", type: "可复试" },
        { color: "#3EFF59", type: "发offer" },
        { color: "#1D7BD8", type: "未入职" },
        { color: "#149c18", type: "已入职" },
      ],

      q_statusList: [
        { color: "#ccc", type: "未标记" },
        { color: "#FF435A", type: "不合格" },
        { color: "#ff9900", type: "可复试" },
        { color: "#3EFF59", type: "发offer" },
        { color: "#1D7BD8", type: "未入职" },
        { color: "#149c18", type: "已入职" },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

      // 复制手机号到剪切板
      onFuzhi(item) {
      //获取数据跳
      //console.log(item.phone);

      const save = function (e) {
        e.clipboardData.setData("text/plain", item.phone);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener("copy", save); // 添加一个copy事件
      document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制微信号成功", type: "success" });
    },

    
    search() {
      getResumeManage({
        page: 1,
        is_online: this.valueType,
        status: this.q_status,
        keyword: this.keyword,
      }).then((res) => {
        this.loading = false;
        console.log("----------444----------------");
        console.log(res.data.resume_list);
        this.tableData = res.data.resume_list;

        this.pageTotal = res.data.all_counts;
      });
    },
    //下拉筛选菜单
    myselect(command) {
      console.log(command);

      this.q_status = command;
      console.log("----------444----------------", this.q_status);
      this.page = 1;

      getResumeManage({
        page: this.page,
        is_online: this.valueType,
        status: this.q_status,
        keyword: this.keyword,
      }).then((res) => {
        this.loading = false;
        console.log("----------444----------------");
        console.log(res.data.resume_list);
        this.tableData = res.data.resume_list;

        this.pageTotal = res.data.all_counts;
      });
    },

    // 进入聊天详情页面
    onChatDetail(item) {
      //获取数据跳转到聊天页面
      console.log(item);
      this.$router.push({
        path: "/chatdetail",
        query: {
          elite_id: item.elite_id,
          jd_id: 0,
        },
      });
    },

    beforeAvatarUpload(file) {
      console.log(file, "before");

      const isJPG =
        file.name.indexOf("xlsx") != -1 || file.name.indexOf("xls") != -1;
      console.log(isJPG, "是否");
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 xls/xlsx 格式!");
      }
      return isJPG;
    },

    handlesucc(response, file, fileList) {
      console.log(file, fileList, response, "success");
      //上传成功的简历 如果有传失败的简历则显示出来
      this.$message.success(`传入成功${response.data.use_count}份简历`);

      if (response.data.mistake_count) {
        this.dialogTableVisible = true;
      } else {
        this.dialogTableVisible = false;
      }

      this.gridData = response.data.mistake_resume_list;
      this.dialogVisible = false;
    },

    // 下载excal模板
    downxlc() {
      console.log("aaaa");
      try {
        var elemIF = document.createElement("iframe");
        elemIF.src =
          "https://oss.kapin.com.cn/public/fa16c300303f46249baa7d9431a84c95.xls?BUCKET=xls-bucket";
        elemIF.style.display = "none";
        document.body.appendChild(elemIF);
      } catch (e) {
        alert("下载异常！");
      }
    },

    Handle(value) {
      console.log(value);
      this.valueType = value;
      this.pageNow = 1;
      this.loading = true;
      getResumeManage({
        page: 1,
        is_online: this.valueType,
        status: this.q_status,
        keyword: this.keyword,
      }).then((res) => {
        console.log("---------11111111111-----------------");
        console.log(res.data.resume_list);

        this.loading = false;
        this.loading = false;
        this.tableData = res.data.resume_list;
        this.pageTotal = res.data.all_counts;
      });
    },
    goDetail(row, column, event) {
      console.log(row, column, event);
      this.$router.push({
        path: "/tooldetails",
        query: {
          id: row.resume_id,
        },
      });
    },

    handleClose() {
      this.dialogVisible = false;
    },

    pageNowHandler(page) {
      if (this.pageNow != page) {
        this.loading = true;
        console.log(11);
        this.pageNow = page;
        getResumeManage({
          page: this.pageNow,
          is_online: this.valueType,
          status: this.q_status,
          keyword: this.keyword,
        }).then((res) => {
          if (res.code === 0) {
            console.log("----------2222222----------------");
            console.log(res.data.resume_list);

            this.loading = false;
            this.tableData = res.data.resume_list;
            this.pageTotal = res.data.all_counts;
          }
        });
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = window.sessionStorage.getItem("token");
    // getResumeManage({ page: this.pageNow, is_online: this.valueType }).then(
    //   res => {
    //     this.loading = false
    //     this.tableData = res.data.resume_list
    //     this.pageTotal = res.data.count
    //   }
    // )
    console.log(this.colorStatus[0].iconfont);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {
    getResumeManage({
      page: this.pageNow,
      is_online: this.valueType,
      status: this.q_status,
      keyword: this.keyword,
    }).then((res) => {
      this.loading = false;
      console.log("----------3333333----------------");
      console.log(res.data.resume_list);
      this.tableData = res.data.resume_list;

      this.pageTotal = res.data.all_counts;
    });
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
  position: relative;
}
// 分页
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.upload-demo {
  text-align: center;
  // position: absolute;
  // z-index: 999;
  // top: 30px;
  // left: 300px;
}

.tips {
  font-size: 14px;
  margin-bottom: 10px;
}
.tips b {
  font-size: 16px;
  color: red;
  font-weight: normal;
  cursor: pointer;
}

.chat {
  width: 30px;
  height: 30px;
}

.get-btn {
  float: left;
  margin-left: 10px;
}

.el-select {
  width: 150px !important;
}

.search /deep/ .el-cascader {
  width: 250px;
}

.search /deep/ .inline_input {
  width: 200px;
}
.search /deep/ .el-input .el-input__inner {
  border: none;
}
.search-input {
  display: flex;
  align-items: center;
  margin-bottom: $padding_top;
}
.search {
  // min-width: 900px;
  border: 1px solid #e3e7ed;
  width: 452px;
  height: 40px;
}
.search-icon {
  width: 40px;
  height: 100%;
  // background: red;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  cursor: pointer;
}
em {
  background-color: #fff;
  height: 40px;
  width: 100%;
  border-left: 1px solid #999999;
}
.inline_input {
  display: inline-block;
  width: 300px;
  margin-left: 10px;
}
.search /deep/ .el-button {
  background-color: #ff8f00;
  color: #fff;
}
// 历史查询记录
.history-search {
  display: inline-block;
  margin-left: 3px;
  cursor: pointer;
}
</style>
